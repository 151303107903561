// get products
export const getProducts = (products, category, type, limit) => {
  const finalProducts = category
    ? products.filter(
        (product) =>
          product.category.filter((single) => single.id === category.id)[0]
      )
    : products;

  if (type && type === "new") {
    const newProducts = finalProducts.sort((a, b) => {
      return (
        new Date(b.createdAt.seconds * 1000).getTime() -
        new Date(a.createdAt.seconds * 1000).getTime()
      );
    });

    //const newProducts = finalProducts.filter((single) => single.new);
    return newProducts.slice(0, limit ? limit : newProducts.length);
  }
  /*if (type && type === "bestSeller") {
    return finalProducts
      .sort((a, b) => {
        return b.saleCount - a.saleCount;
      })
      .slice(0, limit ? limit : finalProducts.length);
  }
  if (type && type === "saleItems") {
    const saleItems = finalProducts.filter(
      (single) => single.discount && single.discount > 0
    );
    return saleItems.slice(0, limit ? limit : saleItems.length);
  }*/
  return finalProducts.slice(0, limit ? limit : finalProducts.length);
};

// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && parseFloat(discount) > 0
    ? (price - price * (parseFloat(discount) / 100)).toFixed(2)
    : null;
};

export const getProductPrice = (product) => {
  let initialPrice = parseFloat(product?.price).toFixed(2);
  let productPrice = initialPrice;
  if (product?.discount !== "" && parseFloat(product?.discount) > 0) {
    productPrice =
      initialPrice - initialPrice * (parseFloat(product?.discount) / 100);
  }
  return parseFloat(productPrice).toFixed(2);
};
// get product cart quantity
export const getProductCartQuantity = (cartItems, product, color, size) => {
  let productInCart = cartItems.filter(
    (single) =>
      single.id === product.id &&
      (single.selectedProductColor
        ? single.selectedProductColor === color
        : true) &&
      (single.selectedProductSize ? single.selectedProductSize === size : true)
  )[0];
  if (cartItems.length >= 1 && productInCart) {
    if (product.variation) {
      return cartItems.filter(
        (single) =>
          single.id === product.id &&
          single.selectedProductColor === color &&
          single.selectedProductSize === size
      )[0].quantity;
    } else {
      return cartItems.filter((single) => product.id === single.id)[0].quantity;
    }
  } else {
    return 0;
  }
};
export const getProductStock = (product) => {
  let productStock = 0;
  if (product !== undefined){
  product.variation.map((variation) => {
    variation.size.map((sizeVariation) => {
      productStock += parseInt(sizeVariation.stock);
    });
  });}
  return productStock;
};

//get products based on category
export const getSortedProducts = (products, filters, searchValue) => {
  let sortedProducts = products;

  let filterCategories = filters.categories;
  let filterColors = filters.colors;
  let filterSize = filters.sizes;
  let filterTags = filters.tag;

  if (sortedProducts) {
    if (
      searchValue !== "" &&
      searchValue !== undefined &&
      searchValue !== null
    ) {
      sortedProducts = sortedProducts?.filter(
        (row) =>
          ["name", "sku", "tag", "id", "description"].some((field) =>
            String(row[field]).toLowerCase().includes(searchValue)
          ) ||
          searchValue
            .split(" ")
            .filter((part) => part)
            .every((part) =>
              ["name", "sku", "tag", "id", "description"].some((field) =>
                String(row[field]).toLowerCase().includes(part)
              )
            )
      );
    }

    if (filterCategories && filterCategories.length > 0) {
      //
      sortedProducts = sortedProducts.filter((product) => {
        let isCategory = -1;
        //
        product.category.map((category) => {
          if (filterCategories.includes(category.value)) {
            isCategory++;
          }
        });
        //
        return isCategory >= 0;
      });
    }

    if (filterColors && filterColors.length > 0) {
      sortedProducts = sortedProducts.filter((product) => {
        let isColor = -1;
        product.variation.map((variation) => {
          if (filterColors.includes(variation.color)) {
            isColor++;
          }
        });
        return isColor >= 0;
      });
    }
    if (filterSize && filterSize.length > 0) {
      //
      filterSize = filterSize.map((size) => {
        if (size.includes("/")) {
          return size.toUpperCase().split("/");
        }
        return size;
      });
      let filterUnique = [...new Set(filterSize.flat(2))];
      //

      sortedProducts = sortedProducts.filter((product) => {
        let isSize = -1;
        product.variation.map((variation) => {
          variation.size.map((size) => {
            //
            //
            filterUnique.forEach((filter) => {
              //
              if (size.name.includes("/")) {
                if (
                  filter
                    .toUpperCase()
                    .includes(size.name.toUpperCase().split("/")[0]) ||
                  filter
                    .toUpperCase()
                    .includes(size.name.toUpperCase().split("/")[1])
                ) {
                  //
                  isSize++;
                }
              } else {
                if (filter.toUpperCase().includes(size.name)) {
                  //
                  isSize++;
                }
              }
            });
          });
        });
        //
        return isSize >= 0;
      });
    }
  }

  if (filterTags && filterTags.length > 0) {
    //
    sortedProducts = sortedProducts.filter((product) => {
      let isTag = -1;
      //
      if (product.tag !== undefined && product.tag.length > 0) {
        product.tag.map((tagTemp) => {
          //
          //

          if (filterTags.includes(tagTemp)) {
            isTag++;
          }
        });
      }
      //
      return isTag >= 0;
    });
  }
  /*
    if (sortType === "filterSort") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "priceHighToLow") {
        return sortProducts.sort((a, b) => {
          return b.price - a.price;
        });
      }
      if (sortValue === "priceLowToHigh") {
        return sortProducts.sort((a, b) => {
          return a.price - b.price;
        });
      }
    }*/
  return sortedProducts;
};

// get individual element
const getIndividualItemArray = (array) => {
  let individualItemArray = array.filter(function (v, i, self) {
    return i === self.indexOf(v);
  });
  return individualItemArray;
};

// get individual categories
export const getIndividualCategories = (products) => {
  let productCategories = [];

  products &&
    products.map((product) => {
      return (
        product.category &&
        product.category.map((single) => {
          return productCategories.push(single);
        })
      );
    });
  let individualItemArray = productCategories.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.value === value.value)
  );

  return individualItemArray;
};

// get individual tags
export const getIndividualTags = (products) => {
  let productTags = [];
  products &&
    products.map((product) => {
      return (
        product.tag &&
        product.tag.map((single) => {
          return productTags.push(single);
        })
      );
    });
  const individualProductTags = getIndividualItemArray(productTags);
  return individualProductTags;
};

// get individual colors
export const getIndividualColors = (products) => {
  let productColors = [];
  products &&
    products.map((product) => {
      return (
        product.variation &&
        product.variation.map((single) => {
          return productColors.push(single.color);
        })
      );
    });
  const individualProductColors = getIndividualItemArray(productColors);
  return individualProductColors;
};

// get individual sizes
export const getProductsIndividualSizes = (products) => {
  let productSizes = [];
  products &&
    products.map((product) => {
      return (
        product.variation &&
        product.variation.map((single) => {
          return single.size.map((single) => {
            return productSizes.push(single.name);
          });
        })
      );
    });
  const individualProductSizes = getIndividualItemArray(productSizes);
  return individualProductSizes;
};

// get product individual sizes
export const getIndividualSizes = (product) => {
  let productSizes = [];
  product.variation &&
    product.variation.map((singleVariation) => {
      return (
        singleVariation.size &&
        singleVariation.size.map((singleSize) => {
          return productSizes.push(singleSize.name);
        })
      );
    });
  const individualSizes = getIndividualItemArray(productSizes);
  return individualSizes;
};

export const setActiveSort = (e) => {
  const filterButtons = document.querySelectorAll(
    ".sidebar-widget-list-left button, .sidebar-widget-tag-left button, .product-filter button"
  );
  if (e.currentTarget.classList.contains("active")) {
    e.currentTarget.classList.remove("active");
  } else {
    e.currentTarget.classList.add("active");
  }
  filterButtons.forEach((item) => {
    //item.classList.remove("active");
  });

  //e.currentTarget.classList.add("active");
};

export const setActiveSortSingle = (e) => {
  const filterButtons = document.querySelectorAll(
    ".sidebar-widget-list-left-single button, .product-filter button"
  );

  filterButtons.forEach((item) => {
    item.classList.remove("active");
  });

  e.currentTarget.classList.add("active");
  //
};
export const setActiveSortSingleWithKey = (key) => {
  const filterButtons = document.querySelectorAll(
    ".sidebar-widget-list-left-single button, .sidebar-widget-tag button, .product-filter button"
  );

  filterButtons.forEach((item) => {
    if (item.id === key) {
      item.classList.add("active");
    } else {
      item.classList.remove("active");
    }
  });
  // e.currentTarget.classList.add("active");
  //
};

export const setActiveLayout = (e) => {
  const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
  gridSwitchBtn.forEach((item) => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

export const toggleShopTopFilter = (e) => {
  const shopTopFilterWrapper = document.querySelector(
    "#product-filter-wrapper"
  );
  shopTopFilterWrapper.classList.toggle("active");
  if (shopTopFilterWrapper.style.height) {
    shopTopFilterWrapper.style.height = null;
  } else {
    shopTopFilterWrapper.style.height =
      shopTopFilterWrapper.scrollHeight + "px";
  }
  e.currentTarget.classList.toggle("active");
};

export const getProductColorPhoto = (product, color) => {
  let firstImageColor = "";

  product.variation.map((variation) => {
    if (variation.color === color) {
      firstImageColor = variation.image[0].image;
    }
  });
  return firstImageColor;
};
