import PropTypes from "prop-types";
import React, {useEffect, Suspense, lazy, useState} from "react";
import ScrollToTop from "./helpers/scroll-top";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {useHistory} from "react-router-dom";

import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import db from "./config/firebase";
import { getDocs, collection, query, where, orderBy } from "firebase/firestore";
import { fetchProducts } from "./redux/actions/productActions";
import { get } from "react-scroll/modules/mixins/scroller";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { fetchCategories } from "./redux/actions/categoryActions";
import { fetchBanners } from "./redux/actions/layoutActions";
import { UserImportBuilder } from "firebase-admin/lib/auth/user-import-builder";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
const HomeFashionTwo = lazy(() => import("./pages/home/HomeFashionTwo"));
const HomeFashionThree = lazy(() => import("./pages/home/HomeFashionThree"));
const HomeFashionFour = lazy(() => import("./pages/home/HomeFashionFour"));
const HomeFashionFive = lazy(() => import("./pages/home/HomeFashionFive"));
const HomeFashionSix = lazy(() => import("./pages/home/HomeFashionSix"));
const HomeFashionSeven = lazy(() => import("./pages/home/HomeFashionSeven"));
const HomeFashionEight = lazy(() => import("./pages/home/HomeFashionEight"));

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridFilter = lazy(() => import("./pages/shop/ShopGridFilter"));
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const ShopGridRightSidebar = lazy(() =>
  import("./pages/shop/ShopGridRightSidebar")
);
const ShopListStandard = lazy(() => import("./pages/shop/ShopListStandard"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const ShopListTwoColumn = lazy(() => import("./pages/shop/ShopListTwoColumn"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Resi = lazy(() => import("./pages/other/Resi"));

const Payment = lazy(() => import("./pages/other/Payment"));
const Confirmation = lazy(() => import("./pages/other/Confirmation"));
const ErrorOrder = lazy(() => import("./pages/other/ErrorOrder"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const Termini = lazy(() => import("./pages/policy/Termini"));
const Privacy = lazy(() => import("./pages/policy/Privacy"));
const ResiPolicy = lazy(() => import("./pages/policy/Resi"));
const SpedizionePolicy = lazy(() => import("./pages/policy/Spedizione"));
const getProducts = (props) => {
  const products = [];
  const queryy = query(
    collection(db, "products"),
    where("visibility", "==", true)
  );
  getDocs(queryy, orderBy("createdAt", "desc")).then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      products.push(doc.data());
    });
    const categories = [];
    getDocs(collection(db, "categories")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        categories.push(doc.data());
      });
      props.dispatch(fetchProducts(products));
      props.dispatch(fetchCategories(categories));
    });
  });
};
const getBanners = (props) => {
  getDocs(collection(db, "banners")).then((querySnapshot) => {
    const banners = [];
    querySnapshot.forEach((doc) => {
      banners.push(doc.data());
      console.log(doc.data());
    });
    props.dispatch(fetchBanners(banners));
  });
};

const App = (props) => {
  useEffect(() => {
    getProducts(props);
    getBanners(props);
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
        },
      })
    );
  }, []);
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        if (location.key) setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle back event
          console.log('back button',location);

        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          console.log('forward button',location);

          // Handle forward event
        }
      }
    });
  }, [locationKeys]);
  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Switch>
              <Route
                exact
                path={process.env.PUBLIC_URL + "/"}
                component={HomeFashion}
              />

              {/* Homepages */}
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion"}
                component={HomeFashion}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-two"}
                component={HomeFashionTwo}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-three"}
                component={HomeFashionThree}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-four"}
                component={HomeFashionFour}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-five"}
                component={HomeFashionFive}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-six"}
                component={HomeFashionSix}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-seven"}
                component={HomeFashionSeven}
              />
              <Route
                path={process.env.PUBLIC_URL + "/home-fashion-eight"}
                component={HomeFashionEight}
              />

              {/* Shop pages */}
              <Route path={"/shop"} component={ShopGridStandard} />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-filter"}
                component={ShopGridFilter}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-two-column"}
                component={ShopGridTwoColumn}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}
                component={ShopGridNoSidebar}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-full-width"}
                component={ShopGridFullWidth}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-grid-right-sidebar"}
                component={ShopGridRightSidebar}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-standard"}
                component={ShopListStandard}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-full-width"}
                component={ShopListFullWidth}
              />
              <Route
                path={process.env.PUBLIC_URL + "/shop-list-two-column"}
                component={ShopListTwoColumn}
              />

              {/* Shop product pages */}
              <Route
                path={process.env.PUBLIC_URL + "/product/:id"}
                render={(routeProps) => (
                  <Product {...routeProps} key={routeProps.match.params.id} />
                )}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                component={ProductTabLeft}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                component={ProductTabRight}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                component={ProductSticky}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-slider/:id"}
                component={ProductSlider}
              />
              <Route
                path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                component={ProductFixedImage}
              />

              {/* Other pages */}
              <Route
                path={process.env.PUBLIC_URL + "/about"}
                component={About}
              />
              <Route
                path={process.env.PUBLIC_URL + "/contact"}
                component={Contact}
              />
              <Route
                path={process.env.PUBLIC_URL + "/my-account"}
                component={MyAccount}
              />
              <Route
                path={process.env.PUBLIC_URL + "/login-register"}
                component={LoginRegister}
              />

              <Route path={process.env.PUBLIC_URL + "/cart"} component={Cart} />
              <Route
                path={process.env.PUBLIC_URL + "/wishlist"}
                component={Wishlist}
              />
              <Route
                path={process.env.PUBLIC_URL + "/compare"}
                component={Compare}
              />
              <Route path={process.env.PUBLIC_URL + "/resi"} component={Resi} />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/checkout"}
                component={Checkout}
              />
              <Route path={"/checkout/payment"} component={Payment} />
              <Route path={"/orderSuccess"} component={Confirmation} />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/orderError"}
                component={ErrorOrder}
              />

              <Route
                path={process.env.PUBLIC_URL + "/not-found"}
                component={NotFound}
              />
              <Route
                path={process.env.PUBLIC_URL + "/policies/terms-of-service"}
                component={Termini}
              />
              <Route
                path={process.env.PUBLIC_URL + "/policies/privacy-cookie"}
                component={Privacy}
              />
              <Route
                path={process.env.PUBLIC_URL + "/policies/condizioni-reso"}
                component={ResiPolicy}
              />
              <Route
                path={
                  process.env.PUBLIC_URL + "/policies/condizioni-spedizione"
                }
                component={SpedizionePolicy}
              />

              <Route exact component={NotFound} />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));
